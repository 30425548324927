import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "./App.css";

function App() {
  const [totalViewsData, setTotalViewsData] = useState(null);
  const [averageData, setAverageData] = useState(null);
  const [medianData, setMedianData] = useState(null);
  const [costPerShowData, setCostPerShowData] = useState(null);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [subscriptionPrice, setSubscriptionPrice] = useState(7.99);
  const [parsedData, setParsedData] = useState(null);

  function getChartOptions(title) {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: title,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setParsedData(results.data);
        setFileLoaded(true);
      },
    });
  };

  const calculateData = (data, subscriptionFee) => {
    const counts = {};
    const countsPerMonth = {};

    data.forEach((item) => {
      if (item.Date) {
        const date = new Date(item.Date);
        const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;
        const day = date.getDate();
        if (!countsPerMonth[yearMonth]) {
          countsPerMonth[yearMonth] = {};
        }
        if (!countsPerMonth[yearMonth][day]) {
          countsPerMonth[yearMonth][day] = 0;
        }
        countsPerMonth[yearMonth][day] += 1;
        counts[yearMonth] = (counts[yearMonth] || 0) + 1;
      }
    });

    const labels = Object.keys(counts).sort();
    const dataPoints = labels.map((label) => counts[label]);

    const daysInEachMonth = labels.map((label) => {
      const [year, month] = label.split("-").map(Number);
      return new Date(year, month, 0).getDate();
    });

    const averages = labels.map((label, index) => {
      const days = Object.values(countsPerMonth[label]);
      const sum = days.reduce((a, b) => a + b, 0);
      return sum / daysInEachMonth[index];
    });

    const medians = labels.map((label) => {
      const days = Object.values(countsPerMonth[label]).sort((a, b) => a - b);
      const mid = Math.floor(days.length / 2);
      return days.length % 2 !== 0
        ? days[mid]
        : (days[mid - 1] + days[mid]) / 2;
    });

    const costPerShow = labels.map((label) => {
      const totalShows = counts[label];
      return totalShows ? (subscriptionFee / totalShows).toFixed(2) : 0;
    });

    setTotalViewsData({
      labels,
      datasets: [
        {
          label: "Number of TV Shows Watched",
          data: dataPoints,
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    });
    setCostPerShowData({
      labels,
      datasets: [
        {
          label: "Average Cost Per Show",
          data: costPerShow,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });
    setAverageData({
      labels,
      datasets: [
        {
          label: "Average Shows Watched Per Day",
          data: averages,
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
      ],
    });

    setMedianData({
      labels,
      datasets: [
        {
          label: "Median Shows Watched Per Day",
          data: medians,
          backgroundColor: "rgba(255, 205, 86, 0.5)",
        },
      ],
    });
  };

  useEffect(() => {
    if (parsedData) {
      calculateData(parsedData, subscriptionPrice);
    }
  }, [parsedData, subscriptionPrice]);

  return (
    <div className="App">
      <h1>Netflix Analysis</h1>
      {!fileLoaded && (
        <div className="input-container">
          <h2>Upload Netflix Viewing Activity</h2>
          <p>
            Download your Netflix viewing activity from{" "}
            <a href="https://www.netflix.com/settings/viewed/" className="red-link">
              Netflix
            </a>{" "}
            and upload it here to view your viewing habits.
          </p>
          <input type="file" onChange={handleFileChange} />
        </div>
      )}
      {fileLoaded && (
        <div className="subscription-container">
          <label>
            Monthly Subscription Price: $
            <input
              type="number"
              step="0.01"
              value={subscriptionPrice}
              onChange={(e) => setSubscriptionPrice(e.target.value)}
              className="subscription-input"
            />
          </label>
        </div>
      )}
      {totalViewsData && (
        <div className="chart-container">
          <Bar
            data={totalViewsData}
            options={getChartOptions("Total TV Shows Watched Per Month")}
          />
        </div>
      )}
      {costPerShowData && (
        <div className="chart-container">
          <Bar
            data={costPerShowData}
            options={getChartOptions("Average Cost Per Show")}
          />
        </div>
      )}
      {averageData && (
        <div className="chart-container">
          <Bar
            data={averageData}
            options={getChartOptions("Average TV Shows Watched Per Day")}
          />
        </div>
      )}
      {/* {medianData && (
        <div className="chart-container">
          <Bar
            data={medianData}
            options={getChartOptions("Median TV Shows Watched Per Day")}
          />
        </div>
      )} */}
    </div>
  );
}

export default App;
